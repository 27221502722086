body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //background: linear-gradient(to bottom, #5F5FFF, #AB6AFF);
    background-color: #5F5FFF;

    scrollbar-face-color: #FFFFFF;
    scrollbar-highlight-color: navy;
    scrollbar-3dlight-color: #FFFFFF;
    scrollbar-shadow-color: navy;
    scrollbar-darkshadow-color: #FFFFFF;
    scrollbar-track-color: #FFFFFF;
    scrollbar-arrow-color: navy
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

