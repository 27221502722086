@use "responsive";

#Home {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    padding: 20px 20px 60px;

    @include responsive.tablet {
        padding: 20px 80px;
    }

    @include responsive.laptop {
        padding: 20px 110px;
    }

    @include responsive.desktop {
        padding: 20px 150px;
    }

    @include responsive.large-screen {
        padding: 20px 206px;
    }

    .home-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: column;
        gap: 40px;
        @include responsive.tablet {
            flex-direction: row;
        }

        .home-text-wrapper {
            display: flex;
            flex-direction: column;

            .home-slogan {
                margin-bottom: 5px;
                font-weight: 600;
                font-size: 30px;
                line-height: 35px;
            }

            .home-logo {
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 50px;
                line-height: 55px;
            }

            .home-description {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
            }

            @include responsive.tablet {
                .home-slogan {
                    margin-bottom: 5px;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 60px;
                }

                .home-logo {
                    margin-bottom: 30px;
                    font-weight: 700;
                    font-size: 64px;
                    line-height: 96px;
                }

                .home-description {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                }
            }
            @include responsive.laptop {
                .home-slogan {
                    margin-bottom: 5px;
                    font-weight: 600;
                    font-size: 50px;
                    line-height: 60px;
                }

                .home-logo {
                    margin-bottom: 30px;
                    font-weight: 700;
                    font-size: 80px;
                    line-height: 96px;
                }

                .home-description {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }

        .home-image {
            width: 100%;
            max-height: 500px;
            object-fit: contain;

            @include responsive.tablet {
                width: 333px;
                height: 444px;
            }
            @include responsive.laptop {
                width: 400px;
                height: 520px;
            }
            @include responsive.desktop {
                width: 468px;
                height: 600px;
            }
            @include responsive.large-screen {
                width: 624px;
                height: 884px;
            }
        }
    }
}
