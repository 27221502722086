@mixin mobile {
    @media only screen and (min-width: 280px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin laptop {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}

@mixin large-screen {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}
