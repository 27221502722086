@use "responsive";

#Header {
    display: flex;
    width: 100%;
    height: 60px;
    padding: 11px 20px;
    background-color: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(150px);

    .logo {
        height: 38px;
        object-fit: contain;
        cursor: pointer;
    }

    @include responsive.tablet {
        height: 80px;
        padding: 16px 20px;

        .logo {
            width: 160px;
            height: 48px;
            object-fit: contain;
        }
    }

}
