#Terms {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;

    .terms-detail {
        width: 100%;
    }
}
