@use "responsive";

#Footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #000000;

    @include responsive.mobile {
        padding: 20px 20px;
    }

    @include responsive.tablet {
        padding: 20px 100px;
    }

    @include responsive.laptop {
        padding: 20px 150px;
    }

    @include responsive.desktop {
        padding: 20px 206px;
    }

    .footer-content {
        display: flex;
        gap: 10px;


        .footer-link {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: underline;
            cursor: pointer;
        }

        .footer-text {
            text-align: start;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        &.gap {
            margin-bottom: 20px;
        }
    }
}
